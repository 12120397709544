export const editorMethods = {
    // add here all the methods, props… to share
    methods: {
        // insère un glyphe à la position du curseur
        insertAtCaret(str, deletePrev) {
            var sel, range;
            if (window.getSelection) {
                sel = window.getSelection();

                if(this.selection.el && this.selection.el.className == 'variant') {
                    this.selection.el.remove()
                }

                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0);
                    range.deleteContents();

                    if (deletePrev) {
                        var clone = range.cloneRange();
                        clone.setStart(range.startContainer, range.startOffset - 1);
                        clone.setEnd(range.startContainer, range.startOffset);
                        clone.deleteContents();
                    }
                    // Range.createContextualFragment() would be useful here but is
                    // only relatively recently standardized and is not supported in
                    // some browsers (IE9, for one)
                    var el = document.createElement("div");
                    el.innerHTML = str;
                    var frag = document.createDocumentFragment(),
                        node, lastNode;
                    while ((node = el.firstChild)) {
                        lastNode = frag.appendChild(node);
                    }
                    range.insertNode(frag);

                    // Preserve the selection
                    if (lastNode) {
                        range = range.cloneRange();
                        range.setStartAfter(lastNode);
                        range.collapse(true);
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                    this.syncSelection()
                }
            } else if (document.selection && document.selection.type != "Control") {
                // IE < 9
                document.selection.createRange().pasteHTML(str);
                this.syncSelection()
            }

            this.resetFilterCode()
        },
        // Supprime x lettres avant le curseur
        deleteBeforeCaret(length) {
            var sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                var range = sel.getRangeAt(0);
                range.deleteContents();
                var clone = range.cloneRange();
                clone.setStart(range.startContainer, range.startOffset - length);
                clone.setEnd(range.startContainer, range.startOffset);
                clone.deleteContents();
                this.syncSelection()
            }
        },
        // Supprime x lettres après le curseur
        deleteAfterCaret(length) {
            var sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                var range = sel.getRangeAt(0);
                    range.deleteContents();
                var clone = range.cloneRange();
                    clone.setStart(range.startContainer, range.startOffset);
                    clone.setEnd(range.startContainer, range.startOffset + length);
                    clone.deleteContents();
                this.syncSelection()
            }
        },
        // Trouve le glyphe qui précède le curseur
        getCharAtCaret(container) {
            var precedingChar = "",
                sel, range, precedingRange;
            if (window.getSelection) {
                sel = window.getSelection();
                if (sel.rangeCount > 0) {
                    range = sel.getRangeAt(0).cloneRange();
                    range.collapse(true);
                    range.setStart(container, 0);
                    let str  = range.toString()
                    precedingChar = this.isCombination(str) ? str.slice(-2) : str.slice(-1);
                }
            } else if ((sel = document.selection) && sel.type != "Control") {
                range = sel.createRange();
                precedingRange = range.duplicate();
                precedingRange.moveToElementText(container);
                precedingRange.setEndPoint("EndToStart", range);
                let str = precedingRange.text
                precedingChar = this.isCombination(str) ? str.slice(-2) : str.slice(-1);
            }
            return precedingChar;
        },
        // détecte si le gylphe est une combinaison de caractères
        isCombination(str) {
            return str.slice(-1).charCodeAt(0) > 10000
        },
    }
}
