export const unicodeMethods = {
    methods: {
        unicodeToHtml(str) {
            return '&#x'+ str +';'
        },
        charToUnicode(textString, parameters = 'none', pad = 4, type = 'hex', mixed = false) {
            // converts a string of characters to code points, separated by space
            // textString: string, the string to convert
            // parameters: string enum [ascii, latin1], a set of characters to not convert
            // pad: boolean, if true, hex numbers lower than 1000 are padded with zeros
            // type: string enum[hex, dec, unicode, 0x], whether output should be in hex or dec or unicode U+ form
            // mixed: boolean, true if Show Latin is selected: causes space separators to be added
            var str = ''
            var number
            var chars = [...textString]

            chars[chars.length] = ' '
            for (let i=0; i<chars.length-1; i++) {
                var cp = chars[i].codePointAt(0)

                if (cp <= 127 && parameters.includes('ascii')) str += chars[i]
                else if (cp <= 255 && parameters.includes('latin1')) str += chars[i]
                else {
                    switch (type) {
                        case 'hex': number = chars[i].codePointAt(0).toString(16).toUpperCase()
                                    if (pad>0) while (number.length < pad) number = '0'+number
                                    if (!mixed) str += number+' '
                                    else {
                                        if (chars[i+1].codePointAt(0) > 127) str += number+' '
                                        else str += number
                                    }
                                    break
                        case 'zerox':  number = chars[i].codePointAt(0).toString(16).toUpperCase()
                                    if (pad>0) while (number.length < pad) number = '0'+number
                                    str += '0x'+number
                                    break
                        case 'unicode': number = chars[i].codePointAt(0).toString(16).toUpperCase()
                                    if (pad>0) while (number.length < pad) number = '0'+number
                                    str += 'U+'+number
                                    break
                        case 'dec': number = cp
                                    if (!mixed) str += number+' '
                                    else {
                                        if (chars[i+1].codePointAt(0) > 127) str += number+' '
                                        else str += number
                                    }
                    }
                }
            }
            return str.trim()
        },
        dec2hex(textString) {
            return (textString+0).toString(16).toUpperCase();
        }
    }
}
