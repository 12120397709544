export const fontData = [
    {
        id: 'pim-latin',
        label: 'PIM Latin',
        format: true,
        direction: 'ltr',
        ontographes: [
            { 'encoding': '0041', 'name': '', 'trans': '', 'variants': 15 },
            { 'encoding': '0042', 'name': '', 'trans': '', 'variants': 15 },
            { 'encoding': '0043', 'name': '', 'trans': '', 'variants': 8 },
            { 'encoding': '0044', 'name': '', 'trans': '', 'variants': 19 },
            { 'encoding': '0045', 'name': '', 'trans': '', 'variants': 10 },
            { 'encoding': '0046', 'name': '', 'trans': '', 'variants': 8 },
            { 'encoding': '0047', 'name': '', 'trans': '', 'variants': 14 },
            { 'encoding': '0048', 'name': '', 'trans': '', 'variants': 4 },
            { 'encoding': '0049', 'name': '', 'trans': '', 'variants': 1 },
            { 'encoding': '004A', 'name': '', 'trans': '', 'variants': 1 },
            { 'encoding': '004B', 'name': '', 'trans': '', 'variants': 2 },
            { 'encoding': '004C', 'name': '', 'trans': '', 'variants': 8 },
            { 'encoding': '004D', 'name': '', 'trans': '', 'variants': 11 },
            { 'encoding': '004E', 'name': '', 'trans': '', 'variants': 11 },
            { 'encoding': '004F', 'name': '', 'trans': '', 'variants': 12 },
            { 'encoding': '0050', 'name': '', 'trans': '', 'variants': 6 },
            { 'encoding': '0051', 'name': '', 'trans': '', 'variants': 3 },
            { 'encoding': '0052', 'name': '', 'trans': '', 'variants': 18 },
            { 'encoding': '0053', 'name': '', 'trans': '', 'variants': 9 },
            { 'encoding': '0054', 'name': '', 'trans': '', 'variants': 9 },
            { 'encoding': '0055', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '0056', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '0057', 'name': '', 'trans': '', 'variants': 1 },
            { 'encoding': '0058', 'name': '', 'trans': '', 'variants': 2 },
            { 'encoding': '0059', 'name': '', 'trans': '', 'variants': 1 },
            { 'encoding': '005A', 'name': '', 'trans': '', 'variants': 1 },
            { 'encoding': '002E', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '003F', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '002A', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '002F', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '0028', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '0029', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '007B', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '007D', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '005B', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '005D', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '002D', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '005F', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '002B', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '003E', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '003C', 'name': '', 'trans': '', 'variants': 0 },
            { 'encoding': '007C', 'name': '', 'trans': '', 'variants': 0 },
        ]
    },
    {
        id: 'pim-archaicgreek',
        label: 'PIM Archaic Greek',
        format: true,
        direction: 'rtl',
        ontographes: [
            { 'encoding': '0391', 'name': 'ALPHA',         'trans': 'a',         'variants': 3 },
            { 'encoding': '0392', 'name': 'BETA',          'trans': 'b',         'variants': 9 },
            { 'encoding': '0393', 'name': 'GAMMA',         'trans': 'g',         'variants': 5 },
            { 'encoding': '0394', 'name': 'DELTA',         'trans': 'd',         'variants': 2 },
            { 'encoding': '0395', 'name': 'EPSILON',       'trans': 'e',         'variants': 4 },
            { 'encoding': '03DC', 'name': 'DIGAMMA (wau)', 'trans': 'w',         'variants': 3 },
            { 'encoding': '0396', 'name': 'ZETA',          'trans': 'z ; dz',    'variants': 1 },
            { 'encoding': '0397', 'name': 'ETA',           'trans': 'ê ; ē',     'variants': 5 },
            { 'encoding': '0370', 'name': 'HETA',          'trans': 'h',         'variants': 2 },
            { 'encoding': '0398', 'name': 'THETA',         'trans': 'th',        'variants': 3 },
            { 'encoding': '0399', 'name': 'IOTA',          'trans': 'i',         'variants': 3 },
            { 'encoding': '039A', 'name': 'KAPPA',         'trans': 'k ; c',     'variants': 1 },
            { 'encoding': '039B', 'name': 'LAMBDA',        'trans': 'l',         'variants': 5 },
            { 'encoding': '039C', 'name': 'MU',            'trans': 'm',         'variants': 3 },
            { 'encoding': '039D', 'name': 'NU',            'trans': 'n',         'variants': 2 },
            { 'encoding': '039E', 'name': 'XI',            'trans': 'x',         'variants': 13 },
            { 'encoding': '039F', 'name': 'OMICRON',       'trans': 'o',         'variants': 4 },
            { 'encoding': '03A0', 'name': 'PI',            'trans': 'p',         'variants': 4 },
            { 'encoding': '03FA', 'name': 'SAN',           'trans': 's ; ś',     'variants': 1 },
            { 'encoding': '03D8', 'name': 'QOPPA',         'trans': 'q',         'variants': 0 },
            { 'encoding': '03A1', 'name': 'RHO',           'trans': 'r ; rh',    'variants': 5 },
            { 'encoding': '03A3', 'name': 'SIGMA',         'trans': 's',         'variants': 3 },
            { 'encoding': '03A4', 'name': 'TAU',           'trans': 't',         'variants': 1 },
            { 'encoding': '03A5', 'name': 'UPSILON',       'trans': 'u ; y',     'variants': 3 },
            { 'encoding': '03A6', 'name': 'PHI',           'trans': 'ph',        'variants': 4 },
            { 'encoding': '03A7', 'name': 'CHI',           'trans': 'ch ; kh',   'variants': 6 },
            { 'encoding': '03A8', 'name': 'PSI',           'trans': 'ps',        'variants': 8 },
            { 'encoding': '03A9', 'name': 'OMEGA',         'trans': 'o ; ô ; ō', 'variants': 3 },
        ]
    },
    {
        id: 'pim-paleohebrew',
        label: 'PIM Paleo Hebrew',
        format: true,
        direction: 'rtl',
        ontographes: false,
    },
    {
        id: 'pim-etruscan',
        label: 'PIM Etruscan',
        format: true,
        direction: 'ltr',
        ontographes: false,
    },
    {
        id: 'pim-oscan',
        label: 'PIM Oscan',
        format: true,
        direction: 'ltr',
        ontographes: false,
    },
    {
        id: 'pim-phoenician',
        label: 'PIM Phoenician',
        format: true,
        direction: 'ltr',
        ontographes: false,
    },
    {
        id: 'pim-umbrian',
        label: 'PIM Umbrian',
        format: true,
        direction: 'ltr',
        ontographes: false,
    },
    {
        id: 'brill',
        label: 'Brill',
        format: true,
        direction: 'ltr',
        ontographes: false,
        groups: [
            {
                name: 'Latin',
                glyphs:[
                    { 'encoding': '0041', 'name': '', 'trans': '', 'variants': 0 },
                    { 'encoding': '0042', 'name': '', 'trans': '', 'variants': 0 },
                    { 'encoding': '0043', 'name': '', 'trans': '', 'variants': 0 },
                    { 'encoding': '0044', 'name': '', 'trans': '', 'variants': 0 },
                    { 'encoding': '0045', 'name': '', 'trans': '', 'variants': 0 },
                    { 'encoding': '0046', 'name': '', 'trans': '', 'variants': 0 },
                ]
            },
            {
                name: 'Greek',
                glyphs:[
                    { 'encoding': '0391', 'name': 'ALPHA',         'trans': '', 'variants': 0 },
                    { 'encoding': '0392', 'name': 'BETA',          'trans': '', 'variants': 0 },
                    { 'encoding': '0393', 'name': 'GAMMA',         'trans': '', 'variants': 0 },
                    { 'encoding': '0394', 'name': 'DELTA',         'trans': '', 'variants': 0 },
                    { 'encoding': '0395', 'name': 'EPSILON',       'trans': '', 'variants': 0 },
                    { 'encoding': '03DC', 'name': 'DIGAMMA (wau)', 'trans': '', 'variants': 0 },
                ]
            }
        ]
    },
];
