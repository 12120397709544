<template>
    <main class="pim-editor">
        <div class="mobile-overlay">
            <div class="mobile-overlay-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path class="cls-1" d="M0,31s0,7,8,7H41a7,7,0,0,0,7-7Z"/><path class="cls-2" d="M44,31V8a4,4,0,0,0-4-4H8A4,4,0,0,0,4,8V31Z"/><path class="cls-3" d="M18,31H30v2a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V31Z"/><rect class="cls-4" x="6" y="8" width="36" height="21"/><path class="cls-5" d="M37,24A10,10,0,1,0,47,34,10,10,0,0,0,37,24Z"/><path class="cls-6" d="M41,35H33a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z"/></svg>
            </div>
            <div class="mobile-overlay-text">
                Cette application recquiert une largeur d'écran supérieure à 1024px, et Google Chrome.
            </div>
        </div>
        <editor-debug v-if="debug" :selection="selection" :selectionClass="selectionClass" />

        <div class="editor-alternates-bar" :class="[selectionClass]" v-if="showAlternates" :style="{right: selection.right, top: selection.top}">
            <div class="name-trans" v-if="currentLetter && (currentLetter.name != '' || currentLetter.trans != '')">
                <span class="name">{{ currentLetter.name }}</span> (<em class="trans">{{ currentLetter.trans }}</em>)
            </div>
            <div class="alternates-list">
                <button v-for="alternate in currentAlternates" :class="['alternate', {selected: isSelected(alternate, 'alt')}]" :key="alternate.var+alternate.key" @click="insertAllographeAtCaret(alternate)">
                    <span :data-variant="alternate.var" v-html="unicodeToHtml(alternate.key)"></span>
                </button>
            </div>
        </div>

        <div class="editor-export input-group" v-click-outside="closeExportOptions">
            <div class="reset" @click="resetEditor">Reset</div>
            <div class="label" v-if="!showExportOptions" @click="toggleExportOptions">Export</div>
            <div class="export-options" v-else>
                <button class="export-button" @click="exportPdf">
                    <span>.pdf</span>
                </button>
                <button class="export-button" @click="exportXml">
                    <span>.xml</span>
                </button>
                <button class="export-button" @click="exportTxt">
                    <span>.txt</span>
                </button>
            </div>
        </div>
        <div class="editor-colors">
            <div class="editor-colors-open" v-if="!showColorsList" @click="openColorsList">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8,14c4.71,0,7.74-5.28,7.87-5.51a1,1,0,0,0,0-1C15.75,7.29,12.73,2,8,2S.25,7.29.13,7.51a1,1,0,0,0,0,1C.25,8.71,3.27,14,8,14ZM8,4c2.84,0,5,2.83,5.82,4-.79,1.17-3,4-5.82,4S3,9.16,2.18,8C3,6.83,5.15,4,8,4Z"/><circle cx="8" cy="8" r="2"/></svg> Afficher les couleurs
            </div>
            <div class="editor-colors-list bg-colors" v-if="showColorsList">
                <div class="color"><span class="circle pim-latin"></span> Latin</div>
                <div class="color"><span class="circle pim-archaicgreek"></span> Archaic Greek</div>
                <div class="color"><span class="circle pim-paleohebrew"></span> Paleo Hebrew</div>
                <div class="color"><span class="circle pim-etruscan"></span> Etruscan</div>
                <div class="color"><span class="circle pim-oscan"></span> Oscan</div>
                <div class="color"><span class="circle pim-phoenician"></span> Phoenician</div>
                <div class="color"><span class="circle pim-umbrian"></span> Umbrian</div>
            </div>
            <div class="editor-colors-close" v-if="showColorsList" @click="closeColorsList">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M14.57,5.67,13.15,7.09c.28.35.51.67.67.91-.76,1.13-2.85,3.82-5.56,4L6.44,13.8A6.67,6.67,0,0,0,8,14c4.71,0,7.74-5.28,7.87-5.51a1,1,0,0,0,0-1A13.8,13.8,0,0,0,14.57,5.67Z"/><path d="M14.54.11a1.14,1.14,0,0,0-.25.18L11.55,3A6.93,6.93,0,0,0,8,2C3.24,2,.25,7.29.13,7.51a1,1,0,0,0,0,1,14.41,14.41,0,0,0,2.69,3.27L.29,14.29l-.11.12,1.4,1.4.13-.1,14-14a1.2,1.2,0,0,0,.18-.26Zm-6,6A2.09,2.09,0,0,0,8,6,2,2,0,0,0,6,8a2.09,2.09,0,0,0,.07.51L4.24,10.34A12.79,12.79,0,0,1,2.18,8C3,6.83,5.15,4,8,4a4.77,4.77,0,0,1,2.08.51Z"/></svg>
            </div>
        </div>

        <div :class="['editor-container', {'bg-colors': showColorsList}]">
            <div id="editor"
                 ref="editor"
                 :style="{width: editor.width + 'px', fontSize: editor.fontSize + 'px', lineHeight: editor.lineHeight}"
                 contenteditable
                 placeholder="Saisissez votre texte"
                 spellcheck="false"
                 :data-align="align"
                 :data-direction="direction"
                 @mouseup="syncSelection" @keyup="syncSelection">
                <div><span class="pim-archaicgreek">ΒΑΣΙΛΕΩΣ ΑΝΤΙΟΞΟΥ&nbsp;</span></div><div><span class="pim-archaicgreek">ΘΕΟΥ ΕΠΙΦΑΝΟΥΣ&nbsp;</span></div><div><span class="pim-archaicgreek">ΝΙΚΗΦΟΡΟΥ</span></div><div><span class="pim-latin">EXEMPLE DE TEXTE LATIN</span></div><br><div><span class="brill">[monnaie] du roi Antiochos dieu qui apparaît,&nbsp; qui apporte la victoire.</span></div><div><span class="brill">[currency] of king Antiochos god who appears, who brings victory.</span></div>
            </div>
        </div>

        <div class="editor-sidebar" v-click-outside="resetFilterCode">
            <div class="input-group">
                <label for="font-family">Font</label>
                <div class="input-group-inner-flex">
                    <div class="select-wrapper">
                        <select :class="[{disabled: disableFontFamily}]" name="font-family" @change="onFontChange">
                            <option v-for="font in fonts" :key="font.id" :value="font.id" :selected="selectionClass == font.id">{{ font.label }}</option>
                        </select>
                    </div>
                    <button  :class="['brill', 'strong', {disabled: disableFormat}, {active: selectionClass == 'strong'}]" @click="toggleBold"><span>B</span></button>
                    <button  :class="['brill', {disabled: disableFormat}, {active: selectionClass == 'em'}]" @click="toggleItalic"><span><em>I</em></span></button>
                    <button  :class="['brill', 'bg-red']" @click="unwrapAll"><span>&times;</span></button>
                </div>
            </div>
            <div class="input-group input-group-columns">
                <div class="column">
                    <label for="text-width">Text width <span class="current">{{ editor.width }}px</span></label>
                    <input type="range" name="text-width" min="400" max="900" value="650" @input="onSliderChange($event, 'text-width')">
                </div>
                <div class="column">
                    <label for="font-size">Font size <span class="current">{{ editor.fontSize }}px</span></label>
                    <input type="range" name="font-size" min="20" max="80" value="40" @input="onSliderChange($event, 'font-size')">
                </div>
                <div class="column">
                    <label for="line-height">Line height <span class="current">&times;{{ editor.lineHeight }}</span></label>
                    <input type="range" name="line-height" min="1" max="2" step="0.05" value="1.2" @input="onSliderChange($event, 'line-height')">
                </div>
                <div class="column column-buttons">
                    <div class="buttons">
                        <button :class="['custom-style', {active: align == 'left'}]" @click="align = 'left'">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188.17 148.79"><rect width="188.17" height="21.87"/><rect y="63.46" width="188.17" height="21.87"/><rect y="126.91" width="112.89" height="21.87"/></svg>
                        </button>
                        <button :class="['custom-style', {active: align == 'center'}]" @click="align = 'center'">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188.17 148.79"><rect width="188.17" height="21.87"/><rect y="63.46" width="188.17" height="21.87"/><rect x="37.64" y="126.91" width="112.89" height="21.87"/></svg>
                        </button>
                        <button :class="['custom-style', {active: align == 'right'}]" @click="align = 'right'">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188.17 148.79"><rect width="188.17" height="21.87"/><rect y="63.46" width="188.17" height="21.87"/><rect x="75.27" y="126.91" width="112.89" height="21.87"/></svg>
                        </button>
                    </div>
                    <div class="buttons">
                        <button :class="['custom-style', {active: direction == 'ltr'}]" @click="direction = 'ltr'">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.15 193.14"><polygon points="137.31 81.86 123.16 97.08 154.9 126.6 39.45 126.6 186.45 21.82 187.88 21.82 187.88 0 0.17 0 0.17 21.82 146.65 21.82 0 126.36 0.17 126.6 0.17 126.6 0.17 148.42 154.89 148.42 123.16 177.92 137.31 193.14 197.15 137.5 137.31 81.86"/></svg>
                        </button>
                        <button :class="['custom-style', {active: direction == 'rtl'}]" @click="direction = 'rtl'">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197.15 193.14"><polygon points="59.84 81.86 74 97.08 42.25 126.6 157.7 126.6 10.7 21.82 9.27 21.82 9.27 0 196.99 0 196.99 21.82 50.51 21.82 197.15 126.36 196.98 126.6 196.99 126.6 196.99 148.42 42.27 148.42 74 177.92 59.84 193.14 0 137.5 59.84 81.86"/></svg>
                        </button>
                    </div>
                </div>

                <button :class="[{active: reverse}, 'button-reverse']" @click="reverse = !reverse" v-if="showOntographes || showAllographes || (currentGlyphsGroups && currentGlyphsGroups.length)">
                    <span v-if="!reverse">Mirror glyphs</span>
                    <span v-else>Mirrored glyphs</span>
                </button>
            </div>
            <div class="input-group input-group-list" v-if="showOntographes">
                <div class="label">
                    Ontographs
                    <span class="name-trans" v-if="currentHighlight && highlightedVariant == '00' && (currentHighlight.name != '' || currentHighlight.trans != '')">
                        <span class="name">{{ currentHighlight.name }}</span> <em class="trans" v-if="currentHighlight.trans != ''">({{ currentHighlight.trans }})</em>
                    </span>
                </div>
                <div :class="['list', selectionClass, {reverse: reverse}, {rtl: currentFont.direction == 'rtl'}]">
                    <button v-for="ontographe in currentOntographesKeys"
                            :class="['ontographe', {active: isActive(ontographe, 'o')}, {selected: isSelected(ontographe, 'o')}, {highlighted: isHighlighted(ontographe, 'o')}, {'filtered': isFiltered(ontographe, 'o')}]" :key="ontographe"
                            @click="setFilterCode(ontographe)"
                            @dblclick="inserOntographeAtCaret(ontographe)"
                            @mouseenter="setHighlightCode(ontographe, '00')" @mouseleave="resetHighlightCode">
                        <span v-html="unicodeToHtml(ontographe)"></span>
                    </button>
                </div>
            </div>
            <div class="input-group input-group-list" v-if="showAllographes">
                <div class="label">
                    Allographs
                    <span class="name-trans" v-if="currentHighlight && highlightedVariant != '00' && (currentHighlight.name != '' || currentHighlight.trans != '')">
                        <span class="name">{{ currentHighlight.name }}</span> <em class="trans" v-if="currentHighlight.trans != ''">({{ currentHighlight.trans }})</em>
                    </span>
                </div>
                <div :class="['list', selectionClass, {reverse: reverse}, {rtl: currentFont.direction == 'rtl'}]">
                    <button v-for="allographe in currentAllographes"
                            :class="['allographe', {active: isActive(allographe, 'a')}, {selected: isSelected(allographe, 'a')}, {hide: allographe.var == '00'}, {highlighted: isHighlighted(allographe, 'a')}, {'filtered': isFiltered(allographe, 'a')}]" :key="allographe.key+allographe.var"
                            @click="insertAllographeAtCaret(allographe)"
                            @mouseenter="setHighlightCode(allographe.key, allographe.var)"
                            @mouseleave="resetHighlightCode">
                        <span :data-variant="allographe.var" v-html="unicodeToHtml(allographe.key)"></span>
                    </button>
                </div>
            </div>
            <div class="input-group input-group-list" v-for="group in currentGlyphsGroups" :key="group.name">
                <div class="label">
                    {{ group.name }}
                    <span class="name-trans" v-if="currentGlyphHighlight && highlightedGroup == group.name && (currentGlyphHighlight.name != '' || currentGlyphHighlight.trans != '')">
                        <span class="name">{{ currentGlyphHighlight.name }}</span> <em class="trans" v-if="currentGlyphHighlight.trans != ''">({{ currentGlyphHighlight.trans }})</em>
                    </span>
                </div>
                <div :class="['list', {reverse: reverse}, {rtl: currentFont.direction == 'rtl'}]">
                    <button v-for="glyph in group.glyphs"
                            :class="['glyph', {selected: isSelected(glyph, 'g')}]"
                            :key="glyph.encoding"
                            @mouseenter="setHighlightCode(glyph.encoding, '00', group.name)"
                            @mouseleave="resetHighlightCode"
                            @click="insertAllographeAtCaret(false, glyph.encoding, '00')">
                        <span :data-variant="glyph.var" v-html="unicodeToHtml(glyph.encoding)"></span>
                    </button>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { saveAs }         from 'file-saver'
import clickOutside       from 'vue-click-outside'
import rangy              from 'rangy'
import                         'rangy/lib/rangy-classapplier'
import                         'rangy/lib/rangy-selectionsaverestore'
import                         'rangy/lib/rangy-textrange'
import { editorMethods }  from './helpers/editor-methods'
import { unicodeMethods } from './helpers/unicode-methods'
import { fontData }       from './data/font-data'
import editorDebug        from './components/editor-debug.vue'

if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function (str) {
        return this.slice(0, str.length) == str;
    };
}

export default {
    name: 'pim-editor',
    components: {editorDebug},
    directives: {clickOutside},
    mixins: [editorMethods, unicodeMethods],
    data() {
        return {
            align: 'left',
            direction: 'ltr',
            debug: false,
            reverse: false,
            showColorsList: true,
            showExportOptions: false,
            highlightedCode: false,
            highlightedVariant: false,
            highlightedGroup: '',
            filterCode: false,
            clickTimeout: null,
            editor: {
                width: 650,
                fontSize: 40,
                lineHeight: 1.2
            },
            selection: {
                collapsed: true,
                length: 0,
                combination: false,
                content: '',
                contentCode: false,
                el: undefined,
                prevChar: false,
                prevCharCode: false,
                top: 0,
                right: 0,
                overlap: false, // à faire = sélection overlaps avec des spans
            },
            fonts: fontData
        }
    },
    computed: {
        currentFont() {
            let font = this.fonts.filter(e => e.id == this.selectionClass)
            return font && font.length ? font[0] : undefined
        },
        showOntographes() {
            return this.currentOntographes && this.currentOntographes.length
        },
        currentGlyphsGroups() {
            let font = this.currentFont
            if(this.disableFontFamily || !font) return []

            return font.groups
        },
        currentOntographes() {
            let font = this.currentFont
            if(this.disableFontFamily || !font) return []

            return font.ontographes
        },
        currentOntographesKeys() {
            if(!this.currentOntographes || !this.currentOntographes.length) return []

            return this.currentOntographes.map(onto => { return onto.encoding })
        },
        showAllographes() {
            return this.currentOntographes && this.currentOntographes.length && this.currentOntographes.filter(onto => { return onto.variants > 0}).length
        },
        currentAllographes() {
            if(!this.showAllographes) return []

            let withVariants = this.currentOntographes.filter(onto => { return onto.variants > 0})
            let list = []

            if(this.filterCode) {
                withVariants = withVariants.filter(onto => { return onto.encoding == this.filterCode})
            }

            withVariants.forEach(onto => {
                let variant = {
                    'key': onto.encoding,
                    'var': '00'
                }
                list.push(variant)

                for (var i = 1; i <= onto.variants; i++) {
                    let variant = {
                        'key': onto.encoding,
                        'var': ('0' + i).slice(-2)
                    }
                    list.push(variant)
                }
            })

            return list
        },
        showAlternates() {
            return this.currentAlternates && this.currentAlternates.length
        },
        currentAlternates() {
            if(!this.showAllographes) return []
            if(this.selection.length > 2 || (this.selection.length == 2 && !this.selection.combination)) return []

            let code = this.selection.contentCode
            let variants = this.currentAllographes.filter(l => { return l.key == code})

            return variants
        },
        disableFormat() {
            return (this.currentFont && this.currentFont.id.startsWith('pim')) || this.selection.overlap
        },
        disableFontFamily() {
            return false
            // return this.selectionClass != 'brill' && !this.selectionClass.startsWith('pim')
        },
        selectionClass() {
            if(!this.selection.el) return ''

            if(this.selection.el.className == 'variant') return this.selection.el.parentNode.className
            else return this.selection.el.className
        },
        formatClasses() {
            let classes = ['strong', 'em']
            let fontClasses = this.fonts.map(a => a.id)

            return classes.concat(fontClasses)
        },
        currentLetter() {
            return !this.selection.collapsed && this.selection.contentCode ? this.currentOntographes.find(l => { return l.encoding == this.selection.contentCode}) : false
        },
        currentHighlight() {
            return this.highlightedCode && this.highlightedGroup == '' ? this.currentOntographes.find(l => { return l.encoding == this.highlightedCode}) : false
        },
        currentGlyphHighlight() {
            if(this.highlightedCode && this.highlightedGroup != '') {
                let group = this.currentFont.groups.find(g => { return g.name == this.highlightedGroup})
                let response = false

                if(group) {
                    let char = group.glyphs.find(c => {return c.encoding == this.highlightedCode})
                    if(char) {
                        response = char
                    }
                }

                return response
            }
            else {
                return false;
            }
        },
    },
    mounted() {
        document.body.setAttribute('spellcheck', false)
        rangy.init()
    },
    methods: {
        // synchronise la sélection storée lorsqu'elle change
        syncSelection() {
            let originalSel = rangy.getSelection()

            if(originalSel.rangeCount > 0) {
                let sel = originalSel.getRangeAt(0)
                let selContainer = sel.commonAncestorContainer.nodeType == 1 ? sel.commonAncestorContainer : sel.commonAncestorContainer.parentElement

                if(selContainer && selContainer.className == 'variant' && selContainer.innerText.length > 1) {
                    let text = selContainer.innerText
                    if(text.length > 1) {
                        let textBefore = text.substring(0, 1)
                        let textAfter  = text.substring(1, text.length)
                        selContainer.innerText = textBefore
                        selContainer.insertAdjacentHTML('afterend', textAfter)

                        let s = rangy.getSelection()
                        let r = s.getRangeAt(0)
                            r.moveStart('character', 2)
                            s.setSingleRange(r)
                    }
                }

                let r        = window.getSelection().getRangeAt(0).getBoundingClientRect();
                let relative = document.body.parentNode.getBoundingClientRect();

                this.selection.collapsed     = sel.collapsed
                this.selection.content       = sel.toString()
                this.selection.length        = this.selection.content.length
                this.selection.contentCode   = this.selection.length <= 2 ? this.charToUnicode(this.selection.content) : false
                this.selection.el            = selContainer
                this.selection.variant       = this.selection.el && this.selection.el.className == 'variant' ? this.selection.el.getAttribute('data-variant') : false
                this.selection.prevChar      = sel.collapsed ? this.getCharAtCaret(document.querySelector('#editor')) : false
                this.selection.combination   = sel.collapsed ? this.isCombination(this.selection.prevChar) : this.isCombination(this.selection.content) && this.selection.length < 3
                this.selection.prevCharCode  = this.selection.prevChar ? this.charToUnicode(this.selection.prevChar) : false
                this.selection.top           = (r.top - relative.top) + 'px'
                this.selection.right         = -(r.right - relative.right) + r.width / 2 +'px'
                this.selection.overlap       = originalSel.focusNode != originalSel.anchorNode && !['strong','em'].includes(this.selectionClass)
            }
            else {
                this.selection.active = false
            }
        },
        onSliderChange(event, type) {
            if(type == 'font-size')        this.editor.fontSize   = event.target.value
            else if(type == 'text-width')  this.editor.width      = event.target.value
            else if(type == 'line-height') this.editor.lineHeight = event.target.value
        },
        resetEditor() {
            let editor = this.$refs.editor

            editor.innerHTML = '<span class="pim-latin">&#8203;</span>'
            editor.focus()
            this.syncSelection()
        },

        /* Highlight classes */
        isActive(l, mode) {
            if(mode == 'a') {
                return this.selection.contentCode == l.key
            }
            else if(mode == 'o') {
                return this.selection.contentCode == l
            }
        },
        isSelected(l, mode) {
            if(mode == 'a' || mode == 'alt') {
                return this.selection.contentCode == l.key && this.selection.variant == l.var
            }
            else if(mode == 'o') {
                return this.selection.contentCode == l && this.selection.variant == false
            }
            if(mode == 'g') {
                return this.selection.contentCode == l.encoding
            }
        },

        setFilterCode(code) {
            var savedSel = rangy.saveSelection()

            this.filterCode = this.filterCode == code ? false : code

            rangy.restoreSelection(savedSel);
        },
        resetFilterCode() {
            this.filterCode = false
        },
        isFiltered(l, mode) {
            if(mode == 'a') {
                return this.filterCode == l.key
            }
            else if(mode == 'o') {
                return this.filterCode == l
            }
        },
        setHighlightCode(code, variant, group = '') {
            this.highlightedCode    = code
            this.highlightedVariant = variant
            this.highlightedGroup   = group
        },
        resetHighlightCode() {
            this.highlightedCode    = false
            this.highlightedVariant = false
            this.highlightedGroup   = ''
        },
        isHighlighted(l, mode) {
            if(mode == 'a') {
                return this.highlightedCode == l.key
            }
            else if(mode == 'o') {
                return this.highlightedCode == l
            }
        },

        /* Text wrapping */
        inserOntographeAtCaret(ontographe) {
            let char = this.unicodeToHtml(ontographe)
            let html = this.reverse ? '<span class="variant" data-reverse data-content="'+ char +'" data-variant="00">'+ char +'</span>' : char

            this.insertAtCaret(html)
        },
        insertAllographeAtCaret(allographe, key = false, variant = false) {
            let char    = allographe ? this.unicodeToHtml(allographe.key) : this.unicodeToHtml(key)
            let charV   = allographe ? allographe.var : variant
            let reverse = this.reverse ? ' data-reverse data-content="'+ char +'"' : ''
            let html    = charV == '00' ? char : '<span class="variant"'+ reverse +' data-variant="'+ charV +'">'+ char +'</span>'

            this.insertAtCaret(html)
        },
        onFontChange(e) {
            let c = e.target
                c = c.options[c.selectedIndex]

            // si le curseur est seul
            if(this.selection.collapsed) {
                let el = this.selection.el
                let selClass = this.selectionClass

                // si il est à l'interieur d'un span
                if (selClass.startsWith('pim-') && el.tagName.toLowerCase() === 'span') {
                    let range = window.getSelection().getRangeAt(0);
                    let preCaretRange = range.cloneRange();

                    preCaretRange.selectNodeContents(el);
                    preCaretRange.setEnd(range.endContainer, range.endOffset);

                    let caretOffset = preCaretRange.toString().length;
                    let text = el.innerText
                    let textBefore = text.substring(0, caretOffset)
                    let textAfter  = text.substring(caretOffset, text.length)

                    el.outerHTML = '<span class="'+ selClass +'">'+ textBefore +'</span><span class="to-select '+ c.value +'">&#8203;</span><span class="'+ selClass +'">'+ textAfter +'</span>'
                }
                else {
                    this.insertAtCaret('<span class="to-select '+ c.value +'">&#8203;</span>')
                }

                // select again
                let sel = rangy.getSelection()
                    sel.removeAllRanges()

                let range    = rangy.createRange()
                let toSelect = document.querySelector('.to-select')

                if(toSelect) {
                    range.selectNodeContents(toSelect)
                    range.collapse(false)
                    sel.setSingleRange(range)
                    toSelect.classList.remove('to-select')
                }
            }
            else {
                this.unwrapAll()
                let applier = rangy.createClassApplier(c.value)
                applier.applyToSelection()
            }



            this.$nextTick(() => {
                this.syncSelection()

                if(this.currentFont) {
                    if(this.currentFont.direction == 'ltr') {
                        this.align = 'left'
                        this.direction = 'ltr'
                    }
                    else if(this.currentFont.direction == 'rtl') {
                        this.align = 'right'
                        this.direction = 'rtl'
                    }
                }
            })
        },
        unwrapAll() {
            this.formatClasses.forEach((_class) => {
                let applier = rangy.createClassApplier(_class);
                applier.undoToSelection()
            })
            this.$nextTick(() => this.syncSelection())
        },
        toggleBold() {
            let applier = rangy.createClassApplier('strong');
            applier.toggleSelection()
            this.$nextTick(() => this.syncSelection())
        },
        toggleItalic() {
            let applier = rangy.createClassApplier('em');
            applier.toggleSelection()
            this.$nextTick(() => this.syncSelection())
        },

        /* Export */
        exportPdf() {
            window.print()
        },
        exportXml() {
            var blob = new Blob([document.querySelector('#editor').innerHTML.trim()], {type: "application/xml;charset=utf-8"});
            saveAs(blob, "pim-export.xml");
        },
        exportTxt() {
            let text = document.querySelector('#editor').textContent.trim()
                text = this.strEncodeUTF16(text)
            // add a BOM for UTF-16 : String.fromCharCode(0xFEFF)
            let blob = new Blob([String.fromCharCode(0xFEFF), text], {type: "text/plain;charset=utf-16"});
            saveAs(blob, "pim-export.txt");
        },
        strEncodeUTF16(str) {
          var buf = new ArrayBuffer(str.length*2);
          var bufView = new Uint16Array(buf);
          for (var i=0, strLen=str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
          }
          return bufView;
        },
        toggleExportOptions() {
            this.showExportOptions = !this.showExportOptions
        },
        closeExportOptions() {
            this.showExportOptions = false
        },
        openColorsList() {
            this.showColorsList = true
        },
        closeColorsList() {
            this.showColorsList = false
        },

        /* Debug */
        toggleDebug() {
            this.debug = !this.debug
        }
    }
};
</script>

<style lang="scss">
    @import './assets/css/styles.scss'
</style>
