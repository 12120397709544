<template>
    <div class="editor-debug">
        <div>collapsed: {{selection.collapsed}}</div>
        <div>combination: {{selection.combination}}</div>
        <div v-if="selection.collapsed">prev: {{selection.prevChar}}</div>
        <div v-if="selection.collapsed">code: {{selection.prevCharCode}}</div>
        <div v-if="!selection.collapsed">length: {{selection.length}}</div>
        <div v-if="!selection.collapsed">content: {{selection.content}}</div>
        <div v-if="!selection.collapsed && selection.contentCode">code: {{selection.contentCode}}</div>
        <div v-if="!selection.collapsed">variant: {{selection.variant}}</div>
        <div>parentClass:     {{selectionClass}}</div>
        <div>overlap:         {{selection.overlap}}</div>
    </div>
</template>

<script>
export default {
    props: ['selection', 'selectionClass']
};
</script>
